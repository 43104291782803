import React, { Component } from "react";
import Axios from "axios";
import { Bars } from "react-loader-spinner";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { toastError } from "../../utils/sysToast.js";
import { ToastContainer, Flip } from "react-toastify";
import logo from "../../images/InfoTechLogoWhite.png";
import logoUp from "../../images/header-image-joomla-extension-rsticketspro.png";

class LoginForm extends Component {
  state = {
    Username: "",
    Password: "",
    isLoading: false,
  };

  handleLogin() {
    this.setState({ isLoading: true });
    if (!this.state.Username || !this.state.Password) {
      toastError("Enter username and password");
      this.setState({ isLoading: false });
    } else {
      Axios.post("/api/account/dashboardlogin", {
        username: this.state.Username,
        password: this.state.Password,
      })
        .then((apiResponse) => {
          this.setState({ isLoading: false });

          if (apiResponse.data.respCode === 0) {
            this.setState({ isLoading: true });
            localStorage.setItem("lastUsername", this.state.Username);
            sessionStorage.setItem("748784", apiResponse.data.respData.jWT);
            window.location.replace("/dashboard/home");
          } else {
            toastError(apiResponse.data.respDesc);
          }
        })
        .catch((apiResponse) => {
          this.setState({ isLoading: false });
          toastError(apiResponse.response.statusText);
        });
    }
  }

  componentDidMount() {
    this.setState({ Username: localStorage.getItem("lastUsername") });
    document
      .getElementById("username")
      .addEventListener("keyup", function (event) {
        event.preventDefault();
        if (event.keyCode === 13) {
          document.getElementById("btnLogin").click();
        }
      });
    document
      .getElementById("password")
      .addEventListener("keyup", function (event) {
        event.preventDefault();
        if (event.keyCode === 13) {
          document.getElementById("btnLogin").click();
        }
      });
  }

  render() {
    return (
      <div className="main">
        <div className="container">
          <center>
            <div className="">
              <div id="login">
                <label
                  style={{
                    fontFamily: "Verdana",
                    fontSize: "22px",
                    color: "white",
                    fontWeight: "bold",
                  }}
                >
                  Ticketing System
                </label>

                <br />
                <img src={logoUp} height="300" width="300" alt="System Logo" />
                <br />
                <InputText
                  id="username"
                  value={this.state.Username}
                  className="login-input"
                  placeholder="Username"
                  onChange={(e) => this.setState({ Username: e.target.value })}
                />
                <InputText
                  id="password"
                  value={this.state.Password}
                  className="login-input"
                  placeholder="Password"
                  type="password"
                  onChange={(e) => this.setState({ Password: e.target.value })}
                />
                <div>
                  <Button
                    id="btnLogin"
                    style={{ paddingLeft: "46%" }}
                    label={
                      this.state.isLoading ? (
                        <Bars color="white" width="30" height="30" />
                      ) : (
                        "Login"
                      )
                    }
                    disabled={this.state.isLoading}
                    className="p-button-raised login-button"
                    onClick={() => {
                      this.handleLogin();
                    }}
                  />
                </div>
                <div className="clearfix" />
                <div className="clearfix" />
              </div>
              <br />
              <br />
              <span
                style={{
                  fontSize: "12px",
                }}
              >
                All rights reserved©{new Date().getFullYear()}
              </span>
              <br />
              <img src={logo} height="30" width="100" alt="System Logo" />
            </div>
          </center>
        </div>

        <ToastContainer
          position="top-center"
          autoClose={4000}
          hideProgressBar={false}
          closeButton={false}
          rtl={false}
          transition={Flip}
          newestOnTop
          closeOnClick
          pauseOnVisibilityChange
          draggable
          pauseOnHover
        />
      </div>
    );
  }
}

export default LoginForm;
