/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import GridTable from "@nadavshaar/react-grid-table";

class Groups extends Component {
  state = {
    data: {
      groupName: "",
      isActive: "",
      mailUrl: "",
      mailUser: "",
      mailPassword: "",
      teamEmail: "",
      emailExceptionList: "",
      domainExceptionList: "",
      newTicketCcList: "",
      teamGroup: [],
    },

    contentHeader: "Groups",
    addButtonLabel: "Add Group",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetGroups",
    singleItemUrl: "/api/data/GetGroup",
    addUrl: "/api/data/AddGroup",
    editUrl: "/api/data/EditGroup",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "Add Group",
    modalSubmitButtonLabelAdd: "Add",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "Edit Group",
    modalSubmitButtonLabelEdit: "Edit",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,

    allUsers: [],
    allStatus: [
      { label: "Yes", value: "Y" },
      { label: "No", value: "N" },
    ],

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "Group",
        field: "GROUP_NAME",
      },
      {
        id: 2,
        label: "Is Active",
        field: "IS_ACTIVE",
      },
      {
        id: 3,
        label: "Mail Url",
        field: "MAIL_URL",
      },
      {
        id: 4,
        label: "Mail User",
        field: "MAIL_USER",
      },
      {
        id: 5,
        label: "Mail Password",
        field: "MAIL_PASSWORD",
      },
      {
        id: 6,
        label: "Group",
        field: "GROUP_NAME",
      },
      {
        id: 7,
        label: "Team Email",
        field: "TEAM_EMAIL",
      },
      {
        id: 8,
        label: "Domain Excpetion List",
        field: "DOMAIN_EXCEPTION_LIST",
      },
      {
        id: 9,
        label: "Email Exception List",
        field: "EMAIL_EXCEPTION_LIST",
      },
      {
        id: 10,
        label: "New Ticket CC List",
        field: "NEW_TICKET_CC_LIST",
      },
      {
        id: 11,
        label: "Insert Date",
        field: "INSERT_DATE",
      },
      {
        id: 12,
        label: "Last Update",
        field: "LAST_UPDATE",
      },
      {
        id: 13,
        label: "Actions",
        field: "SYS_ACTINOS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CB05"]) && (
                <div className="inner" title="Edit">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
    this.getAllUsers();
  }

  getAllUsers() {
    post(
      "/api/data/GetTechniciansDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allUsers: apiResponse.data.respData.data,
      });
    });
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
      allClaims: [],
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["groupName"] = apiResponse.data.respData.data[0].GROUP_NAME;
      data["isActive"] = apiResponse.data.respData.data[0].IS_ACTIVE;
      data["mailUrl"] = apiResponse.data.respData.data[0].MAIL_URL;
      data["mailUser"] = apiResponse.data.respData.data[0].MAIL_USER;
      data["mailPassword"] = apiResponse.data.respData.data[0].MAIL_PASSWORD;
      data["teamEmail"] = apiResponse.data.respData.data[0].TEAM_EMAIL;
      data["emailExceptionList"] =
        apiResponse.data.respData.data[0].EMAIL_EXCEPTION_LIST;
      data["domainExceptionList"] =
        apiResponse.data.respData.data[0].DOMAIN_EXCEPTION_LIST;
      data["newTicketCcList"] =
        apiResponse.data.respData.data[0].NEW_TICKET_CC_LIST;

      const team = apiResponse.data.respData.data[0].TEAM_GROUP.split(",");

      data["teamGroup"] = [];
      for (var i = 0; i < team.length; i++) {
        console.log("g");
        data["teamGroup"].push(team[i]);
      }

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
      console.log(this.state.data.teamGroup);
    });
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    if (this.state.inEditMode) {
      submitData["groupId"] = this.state.editEntityId;
    }
    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      submitData,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      console.log(apiResponse);
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
      }
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CB04"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "groupName",
                label: "Group Name",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "isActive",
                label: "Is Active",
                options: this.state.allStatus,
                disabled: false,
              },
              {
                type: "text",
                id: "mailUrl",
                label: "Mail Url",
                disabled: false,
              },
              {
                type: "text",
                id: "mailUser",
                label: "Mail User",
                disabled: false,
              },
              {
                type: "text",
                id: "mailPassword",
                label: "Mail Password",
                disabled: false,
              },
              {
                type: "text",
                id: "teamEmail",
                label: "Team Email",
                disabled: false,
              },
              {
                type: "text",
                id: "emailExceptionList",
                label: "Email Exception List",
                disabled: false,
              },
              {
                type: "text",
                id: "newTicketCcList",
                label: "Domain Exception List",
                disabled: false,
              },
              {
                type: "text",
                id: "domainExceptionList",
                label: "New Ticket CC List(separated by ,)",
                disabled: false,
              },
              {
                type: "multiselect",
                id: "teamGroup",
                label: "Team Group",
                options: this.state.allUsers,
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default Groups;
