/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { post, downloadFile } from "../../../utils/sysAxios";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import "../../../styles/table.css";
import queryString from "query-string";
import sysJwt from "../../../utils/sysJwt";
import { Dialog } from "primereact/dialog";
import SysForm from "../../commonComponents/sysForm";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { getStatusColor } from "../../../utils/sysHelpers";

class TicketView extends Component {
  state = {
    data: {
      assignTo: "",
      status: "",
      timeSpent: "",
      priority: "",
      category: "",
      client: "",
      solutionLocation: "",
    },

    allTimes: [],

    queryString: queryString.parse(window.location.search),
    ticketInfo: {},
    attach: [],
    ticketLogs: [],
    ticketNotes: [],
    reply: null,
    files: "",
    replyBtn: true,
    viewNotes: false,
    note: "",

    modalWidth: "50vw",

    contentHeader:
      "Ticket - " + queryString.parse(window.location.search).ticketId,

    dataUrl: "/api/data/getticketinfo",

    allUsers: [],
    allStatus: [],
    allCategories: [],
    allPriorities: [],
    allClients: [],
    allSolutionLocation: [
      { label: "Onsite", value: "Onsite" },
      { label: "Remotely", value: "Remotely" },
    ],

    inModalModeChangeAssigne: false,
    inModalModeChangeStatus: false,
    inModalModeUpdate: false,
  };
  componentDidMount() {
    var allTime = [
      { label: "00:00", value: "00:00" },
      { label: "00:30", value: "00:30" },
    ];
    for (let i = 1; i < 200; i++) {
      const co = i.toString();
      allTime.push({
        label: co.padStart(2, "0") + ":00",
        value: co.padStart(2, "0") + ":00",
      });
      allTime.push({
        label: co.padStart(2, "0") + ":30",
        value: co.padStart(2, "0") + ":30",
      });
    }
    this.setState({ allTimes: allTime });

    this.getData();
    this.getAllUsers();
    this.getAllStatus();
    this.getAllCategories();
    this.getAllPriorities();
    this.getAllClients();
  }

  getAllClients() {
    post(
      "/api/data/GetAllClientsDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allClients: apiResponse.data.respData.data,
      });
    });
  }

  getAllUsers() {
    post(
      "/api/data/GetTechnicianUsersDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allUsers: apiResponse.data.respData.data,
      });
    });
  }

  getAllStatus() {
    post(
      "/api/data/GetAllStatusDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allStatus: apiResponse.data.respData.data,
      });
    });
  }

  getAllCategories() {
    post(
      "/api/data/getallcategoriesdropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allCategories: apiResponse.data.respData.data,
      });
    });
  }

  getAllPriorities() {
    post(
      "/api/data/getallprioritiesdropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allPriorities: apiResponse.data.respData.data,
      });
    });
  }

  getData() {
    post(
      this.state.dataUrl,
      { ticketId: this.state.queryString.ticketId },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({ ticketInfo: apiResponse.data.respData.ticket });
      this.setState({ ticketLogs: apiResponse.data.respData.ticketLogs });
      this.setState({ ticketNotes: apiResponse.data.respData.ticketNotes });
    });
  }

  handleAssignToMe() {
    confirmAlert({
      title: "Assign To Me",
      message: "Are you sure to assign this ticket for you?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            post(
              "/api/data/assigntickettome",
              { ticketId: this.state.queryString.ticketId },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "Cancel",
          onClick: () => null,
        },
      ],
    });
  }

  showChangeAssign() {
    this.setState({ inModalModeChangeAssigne: true });
  }

  closeModalChangeAssign() {
    this.setState({
      inModalModeChangeAssigne: false,
    });
    this.getData();
  }

  showModalUpdate() {
    var data = this.state.data;
    data["priority"] = this.state.ticketInfo.PRIORITY;
    data["category"] = this.state.ticketInfo.CATEGORY;
    data["client"] = this.state.ticketInfo.CLIENTX;
    data["solutionLocation"] = this.state.ticketInfo.SOLUTION_LOCATION;

    this.setState({ inModalModeUpdate: true, data: data });
  }

  closeModalUpdate() {
    this.setState({
      inModalModeUpdate: false,
    });
    this.getData();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  handleChangeAssign() {
    post(
      "/api/data/changeassignticket",
      {
        ticketId: this.state.queryString.ticketId,
        assignTo: this.state.data.assignTo,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.getData();
        this.closeModalChangeAssign();
      }
    });
  }

  showChangeStatus() {
    this.setState({ inModalModeChangeStatus: true });
  }

  closeModalChangeStatus() {
    this.setState({
      inModalModeChangeStatus: false,
    });
    this.getData();
  }

  handleChangeStatus() {
    post(
      "/api/data/changeticketstatus",
      {
        ticketId: this.state.queryString.ticketId,
        status: this.state.data.status,
        timeSpent: this.state.data.timeSpent,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.getData();
        this.closeModalChangeStatus();
      }
    });
  }

  handleUpdate() {
    post(
      "/api/data/UpdateTicket",
      {
        ticketId: this.state.queryString.ticketId,
        priority: this.state.data.priority,
        category: this.state.data.category,
        client: this.state.data.client,
        solutionLocation: this.state.data.solutionLocation,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.getData();
        this.closeModalUpdate();
      }
    });
  }

  handleAddReply() {
    const data = new FormData();

    if (this.state.files) {
      Array.from(this.state.files).map((member) =>
        data.append("files", member, member.name)
      );
    }

    data.append(
      "requestData",
      JSON.stringify({
        ticketId: this.state.queryString.ticketId,
        replyMessage: this.state.reply,
      })
    );
    post("/api/data/addreply", data, this.props.updateIsLoading).then(
      (apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.setState({ reply: "" });
          this.setState({ files: "" });
          this.getData();
        }
      }
    );
  }

  handleAddNote() {
    post(
      "/api/data/AddNote",
      { ticketId: this.state.queryString.ticketId, notes: this.state.note },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.setState({ note: "" });
        this.getData();
      }
    });
  }

  handleDeleteNote(noteId) {
    confirmAlert({
      title: "Delete Note",
      message: "Are you sure you want to delete this note?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            post(
              "/api/data/DeleteNote",
              { noteId },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              if (apiResponse.data.respCode === 0) {
                this.getData();
              }
            });
          },
        },
        {
          label: "Cancel",
          onClick: () => null,
        },
      ],
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            <h3>
              <spna>
                <i
                  className="fa fa-arrow-circle-left"
                  onClick={() => {
                    window.location.replace(
                      "/dashboard/" + this.state.queryString.source
                    );
                  }}
                ></i>
              </spna>
            </h3>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {sysJwt.isAuthorized(["CAF2"]) && (
              <Button
                label="Assign To Me"
                icon="fa fa-hand-paper-o"
                className="p-button-rounded p-button-success"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.handleAssignToMe();
                }}
              />
            )}
            &nbsp;
            {sysJwt.isAuthorized(["CAF3"]) && (
              <Button
                label="Change Assign"
                icon="fa fa-address-card-o"
                className="p-button-rounded p-button-primary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.showChangeAssign();
                }}
              />
            )}
            &nbsp;
            {sysJwt.isAuthorized(["CAF4"]) && (
              <Button
                label="Change Status"
                icon="fa fa-indent"
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.showChangeStatus();
                }}
              />
            )}
            &nbsp;
            {sysJwt.isAuthorized(["CAFB"]) && (
              <Button
                label="Update"
                icon="fa fa-pencil-square-o"
                className="p-button-rounded p-button-warning"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.showModalUpdate();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalModeChangeAssigne}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModalChangeAssign()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">Change Assign</h5>}
          footer={
            <div>
              <Button
                label="Chnage"
                icon="fa fa-address-book-o"
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.handleChangeAssign()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModalChangeAssign()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode="false"
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "dropdown",
                id: "assignTo",
                label: "Assign To",
                options: this.state.allUsers,
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <Dialog
          visible={this.state.inModalModeChangeStatus}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModalChangeStatus()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">Change Status</h5>}
          footer={
            <div>
              <Button
                label="Change"
                icon="fa fa-indent"
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.handleChangeStatus()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModalChangeStatus()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode="false"
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "dropdown",
                id: "status",
                label: "Status",
                options: this.state.allStatus,
                disabled: false,
              },
              {
                type: "dropdown",
                id: "timeSpent",
                label: "Time Spent",
                options: this.state.allTimes,
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <Dialog
          visible={this.state.inModalModeUpdate}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModalUpdate()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">Update Ticket</h5>}
          footer={
            <div>
              <Button
                label="Update"
                icon="fa fa-pencil-square-o"
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.handleUpdate()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModalUpdate()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode="false"
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "dropdown",
                id: "priority",
                label: "Priority",
                options: this.state.allPriorities,
                disabled: false,
              },
              {
                type: "dropdown",
                id: "category",
                label: "Category",
                options: this.state.allCategories,
                disabled: false,
              },
              {
                type: "dropdown",
                id: "client",
                label: "Client",
                options: this.state.allClients,
                disabled: false,
              },
              {
                type: "dropdown",
                id: "solutionLocation",
                label: "Solution Location",
                options: this.state.allSolutionLocation,
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <div className="row">
          <div className="col-4">
            <h4>
              Ticket Information{" "}
              {sysJwt.isAuthorized(["CB00"]) ? (
                !this.state.viewNotes ? (
                  <i
                    className="fa fa-commenting"
                    title="View Notes"
                    onClick={() => {
                      this.setState({ viewNotes: true });
                    }}
                  />
                ) : (
                  <i
                    className="fa fa-reply-all"
                    title="View Replies"
                    onClick={() => {
                      this.setState({ viewNotes: false });
                    }}
                  />
                )
              ) : (
                ""
              )}
            </h4>
            <div className="card" style={{ marginLeft: "4px" }}>
              <div className="card-body">
                <h5 className="card-title">
                  {this.state.ticketInfo.SUBJECTX}
                  {this.state.ticketInfo.HAS_ATTACHMENTS === true ? (
                    <i className="fa fa-paperclip" />
                  ) : (
                    ""
                  )}
                </h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {this.state.ticketInfo.FROMX}
                </h6>
                <p className="card-text">
                  <b>ID:</b> {this.state.ticketInfo.ID}
                </p>
                <p className="card-text">
                  <b>Status:</b>&nbsp;
                  <span
                    style={{
                      color: "white",
                      backgroundColor: getStatusColor(
                        this.state.ticketInfo.STATUSX
                      ),
                      "border-radius": "3px",
                    }}
                  >
                    <b>&nbsp;{this.state.ticketInfo.STATUSX}&nbsp;</b>
                  </span>
                </p>
                <p className="card-text">
                  <b>Insert Date:</b> {this.state.ticketInfo.INSERT_DATE}
                </p>
                <p className="card-text">
                  <b>Last Update:</b> {this.state.ticketInfo.LAST_UPDATE}
                </p>
                <p className="card-text">
                  <b>Assigned To:</b> {this.state.ticketInfo.ASSIGNED_TO}
                </p>
                <p className="card-text">
                  <b>Assigned Date:</b> {this.state.ticketInfo.ASSIGNED_DATE}
                </p>
                <p className="card-text">
                  <b>Category:</b> {this.state.ticketInfo.CATEGORY}
                </p>
                <p className="card-text">
                  <b>Priority:</b>{" "}
                  <span
                    style={{
                      color: "white",
                      fontSize: 10,
                      backgroundColor:
                        this.state.ticketInfo.PRIORITY == "Low"
                          ? "#C8C7C7"
                          : this.state.ticketInfo.PRIORITY == "Medium"
                          ? "#FFB106"
                          : this.state.ticketInfo.PRIORITY == "High"
                          ? "#E41F1F"
                          : "",
                      "border-radius": "2px",
                    }}
                  >
                    <b>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</b>
                  </span>{" "}
                  {this.state.ticketInfo.PRIORITY}
                </p>
                <p className="card-text">
                  <b>Due Date:</b> {this.state.ticketInfo.DUE_DATE}
                </p>
                <p className="card-text">
                  <b>Time Spent:</b> {this.state.ticketInfo.TIME_SPENT}
                </p>
                <p className="card-text">
                  <b>Content:</b>{" "}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: this.state.ticketInfo.CONTENT,
                    }}
                  ></div>
                </p>
                {this.state.ticketInfo.HAS_ATTACHMENTS === true ? (
                  <p>Attachments</p>
                ) : (
                  ""
                )}
                {this.state.ticketInfo.HAS_ATTACHMENTS === true ? (
                  <ul className="list-group list-group-horizontal position-relative overflow-auto">
                    {this.state.ticketInfo.ATTACHMENTS
                      ? this.state.ticketInfo.ATTACHMENTS.map((member) => (
                          <li
                            className="list-group-item"
                            style={{
                              width: "110px",
                              height: "50px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              "text-overflow": "ellipsis",
                            }}
                          >
                            <a
                              title={member.FILE_NAME}
                              style={{
                                color: "black",
                                fontSize: "12px",
                                cursor: "pointer",
                              }}
                              className="underline-on-hover"
                              onClick={() => {
                                downloadFile(
                                  member.FILE_PATH,
                                  this.props.updateIsLoading
                                );
                              }}
                            >
                              {member.FILE_NAME}
                            </a>
                          </li>
                        ))
                      : ""}
                  </ul>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          {!this.state.viewNotes ? (
            <div className="col-8">
              <div className="row">
                <div className="col-9">
                  <h4>Ticket Replies</h4>
                </div>
                <div className="col-3">
                  {this.state.replyBtn && sysJwt.isAuthorized(["CAF5"]) ? (
                    <Button
                      label="Add Reply"
                      className="p-button-rounded p-button-secondary"
                      disabled={this.props.isLoading}
                      icon="fa fa-reply"
                      onClick={() => {
                        this.setState({ replyBtn: false });
                      }}
                      style={{ paddingBottom: "2px", paddingTop: "0px" }}
                    />
                  ) : (
                    <Button
                      label="Close"
                      className="p-button-rounded p-button-secondary"
                      disabled={this.props.isLoading}
                      icon="fa fa-times"
                      onClick={() => {
                        this.setState({ replyBtn: true });
                      }}
                      style={{ paddingBottom: "2px", paddingTop: "0px" }}
                    />
                  )}
                </div>
              </div>

              {!this.state.replyBtn && sysJwt.isAuthorized(["CAF5"]) && (
                <div className="card border-secondary">
                  <div className="row">
                    <div className="col-12">
                      <ReactQuill
                        theme="snow"
                        value={this.state.reply}
                        onChange={(e) => {
                          this.setState({ reply: e });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-10" style={{ "padding-top": "5px" }}>
                      <input
                        type="file"
                        multiple="multiple"
                        onChange={(e) => {
                          this.setState({ files: e.target.files });
                        }}
                      ></input>
                    </div>
                    <div
                      className="col-2"
                      style={{ paddingBottom: "2px", "padding-top": "2px" }}
                    >
                      <Button
                        label="Submit"
                        className="p-button-rounded p-button-secondary"
                        icon="fa fa-location-arrow"
                        disabled={this.props.isLoading}
                        onClick={() => {
                          this.handleAddReply();
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <hr />
              {this.state.ticketLogs.map((member) => (
                <div
                  className="card border-secondary"
                  style={{ "margin-bottom": "5px" }}
                >
                  <div className="card-header">
                    {member.FROMX}
                    {member.HAS_ATTACHMENTS === true ? (
                      <i className="fa fa-paperclip" />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="card-body">
                    <p className="card-title" style={{ fontSize: 11 }}>
                      {member.INSERT_DATE} - {member.TYPEX}
                    </p>
                    <p
                      className="card-text"
                      dangerouslySetInnerHTML={{
                        __html: member.CONTENT,
                      }}
                    ></p>
                    {member.HAS_ATTACHMENTS === true ? <p>Attachments</p> : ""}
                    {member.HAS_ATTACHMENTS === true ? (
                      <ul className="list-group list-group-horizontal position-relative overflow-auto">
                        {member.ATTACHMENTS
                          ? member.ATTACHMENTS.map((member) => (
                              <li
                                className="list-group-item"
                                style={{
                                  width: "110px",
                                  height: "50px",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                  "text-overflow": "ellipsis",
                                }}
                              >
                                <a
                                  title={member.FILE_NAME}
                                  style={{
                                    color: "black",
                                    fontSize: "12px",
                                    cursor: "pointer",
                                  }}
                                  className="underline-on-hover"
                                  onClick={() => {
                                    downloadFile(
                                      member.FILE_PATH,
                                      this.props.updateIsLoading
                                    );
                                  }}
                                >
                                  {member.FILE_NAME}
                                </a>
                              </li>
                            ))
                          : ""}
                      </ul>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
          {this.state.viewNotes ? (
            <div className="col-8">
              <div className="row">
                <div className="col-9">
                  <h4>Ticket Notes</h4>
                </div>
              </div>

              {sysJwt.isAuthorized(["CB01"]) && (
                <div className="card border-secondary">
                  <div className="row">
                    <div className="col-12">
                      <textarea
                        type="multiline"
                        className="form-control form-control-sm"
                        value={this.state.note}
                        onChange={(e) => {
                          this.setState({ note: e.target.value });
                        }}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className="col-2"
                      style={{ paddingBottom: "2px", "padding-top": "2px" }}
                    >
                      <Button
                        label="Add"
                        className="p-button-rounded p-button-secondary"
                        icon="fa fa-plus"
                        disabled={this.props.isLoading}
                        onClick={() => {
                          this.handleAddNote();
                        }}
                      />
                    </div>
                  </div>
                </div>
              )}
              <hr />
              {this.state.ticketNotes.map((member) => (
                <div
                  className="card border-secondary"
                  style={{ "margin-bottom": "5px" }}
                >
                  <div className="card-header">
                    <div className="row">
                      <div className="col-11">{member.FROMX}</div>
                      {sysJwt.isAuthorized(["CB02"]) && (
                        <div className="col-1">
                          <i
                            className="fa fa-trash"
                            title="Delete Note"
                            onClick={() => {
                              this.handleDeleteNote(member.ID);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="card-body">
                    <p className="card-title" style={{ fontSize: 11 }}>
                      {member.INSERT_DATE}
                    </p>
                    <p className="card-text">{member.CONTENT}</p>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}

export default TicketView;
