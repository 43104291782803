/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import GridTable from "@nadavshaar/react-grid-table";

class Categories extends Component {
  state = {
    data: {
      id: "",
      status: "",
      color: "",
    },

    contentHeader: "Project Status",
    addButtonLabel: "Add Project Status",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetAllProjectStatus",
    singleItemUrl: "/api/data/GetProjectStatus",
    addUrl: "/api/data/AddProjectStatus",
    editUrl: "/api/data/EditProjectStatus",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "Add Project Status",
    modalSubmitButtonLabelAdd: "Add",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "Edit Project Status",
    modalSubmitButtonLabelEdit: "Edit",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "ID",
        field: "ID",
      },
      {
        id: 2,
        label: "Status",
        field: "STATUSX",
      },
      {
        id: 3,
        label: "Color",
        field: "COLOR",
      },
      {
        id: 4,
        label: "Actions",
        field: "SYS_ACTINOS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CB10"]) && (
                <div className="inner" title="Edit">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { id: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["id"] = apiResponse.data.respData.data[0].ID;
      data["status"] = apiResponse.data.respData.data[0].STATUSX;
      data["color"] = apiResponse.data.respData.data[0].COLOR;

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    if (this.state.inEditMode) {
      submitData["statusId"] = this.state.editEntityId;
    }
    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      submitData,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      console.log(apiResponse);
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
      }
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CB0F"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "id",
                label: "ID",
                disabled: this.state.inEditMode ? true : false,
              },
              {
                type: "text",
                id: "status",
                label: "Status",
                disabled: false,
              },
              {
                type: "text",
                id: "color",
                label: "Color",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default Categories;
