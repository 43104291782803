/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { post } from "../../utils/sysAxios";
import { Button } from "primereact/button";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

class NewTicket extends Component {
  state = {
    data: {},

    contentHeader: "User Profile",

    CurrentPassword: "",
    NewPassword: "",
    PasswordConfirmation: "",
    signature: "",
  };
  componentDidMount() {
    this.getProfile();
  }

  getProfile() {
    post("/api/account/GetProfile", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        console.log(apiResponse);
        this.setState({
          signature: apiResponse.data.respData.SIGNATURE,
        });
      }
    );
  }

  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  handleChangePassword() {
    post(
      "/api/account/changepassword",
      {
        CurrentPassword: this.state.CurrentPassword,
        NewPassword: this.state.NewPassword,
        PasswordConfirmation: this.state.PasswordConfirmation,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(true);
        this.sleep(4000).then(() => window.location.replace("/login"));
      }
    });
  }

  handleUpdateProfile() {
    post(
      "/api/account/updateprofile",
      {
        signature: this.state.signature,
      },
      this.props.updateIsLoading
    );
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
        </div>
        <hr />
        <div className="row" style={{ marginLeft: "2px" }}>
          <div className="col-4">
            <div className="card border-secondary">
              <h3>Change Password</h3>
              <div className="row paddingRowClass">
                <div className="col-12">
                  <label>Current Password</label>
                  <input
                    type="password"
                    className="form-control form-control-sm"
                    value={this.state.CurrentPassword}
                    onChange={(e) => {
                      this.setState({ CurrentPassword: e.target.value });
                    }}
                  ></input>
                </div>
                <div className="col-12">
                  <label>New Password</label>
                  <input
                    type="password"
                    className="form-control form-control-sm"
                    value={this.state.NewPassword}
                    onChange={(e) => {
                      this.setState({ NewPassword: e.target.value });
                    }}
                  ></input>
                </div>
                <div className="col-12">
                  <label>Password Confirmation</label>
                  <input
                    type="password"
                    className="form-control form-control-sm"
                    value={this.state.PasswordConfirmation}
                    onChange={(e) => {
                      this.setState({ PasswordConfirmation: e.target.value });
                    }}
                  ></input>
                </div>
              </div>
              <div className="row paddingRowClass">
                <div
                  className="col-2"
                  style={{ paddingBottom: "2px", paddingTop: "2px" }}
                >
                  <Button
                    label="Change"
                    className="p-button-rounded p-button-warning"
                    icon="fa fa-lock"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.handleChangePassword();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="card border-secondary">
              <h3>Profile</h3>
              <label>Signature</label>
              <div className="row paddingRowClass">
                <div className="col-12">
                  <ReactQuill
                    theme="snow"
                    value={this.state.signature}
                    onChange={(e) => {
                      this.setState({ signature: e });
                    }}
                  />
                </div>
              </div>
              <div className="row paddingRowClass">
                <div
                  className="col-2"
                  style={{ paddingBottom: "2px", paddingTop: "2px" }}
                >
                  <Button
                    label="Update"
                    className="p-button-rounded p-button-secondary"
                    icon="fa fa-pencil-square-o"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.handleUpdateProfile();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default NewTicket;
