/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post, downloadFile } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import GridTable from "@nadavshaar/react-grid-table";
import { confirmAlert } from "react-confirm-alert";
import queryString from "query-string";

class Files extends Component {
  state = {
    data: {
      attachType: "",
      file: "",
    },

    queryString: queryString.parse(window.location.search),

    contentHeader:
      "Support&Services - Files - " +
      queryString.parse(window.location.search).supportServiceId,
    addButtonLabel: "Add New File",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetSupportServicesAttachment",
    addUrl: "/api/data/AddSupportServicesAttachment",
    deleteUrl: "/api/data/DeleteSupportServicesAttachment",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "Add Support&Services File",
    modalSubmitButtonLabelAdd: "Add",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalWidth: "70vw",

    editEntityId: 0,

    allTypes: [],
    allClients: [],

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "ID",
        field: "ID",
      },
      {
        id: 2,
        label: "File Name",
        field: "FILE_NAME",
      },
      {
        id: 3,
        label: "Type",
        field: "TYPE",
      },
      {
        id: 4,
        label: "Insert Date",
        field: "INSERT_DATE",
      },
      {
        id: 5,
        label: "File",
        field: "FILE_PATH",
        cellRenderer: ({ data }) => {
          return (
            <a
              style={{
                color: "black",
                fontSize: "12px",
                cursor: "pointer",
              }}
              className="underline-on-hover"
              onClick={() => {
                downloadFile(data.FILE_PATH, this.props.updateIsLoading);
              }}
            >
              Download File
            </a>
          );
        },
      },
      {
        id: 6,
        label: "Actions",
        field: "SYS_ACTINOS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CB1C"]) && (
                <div className="inner" title="Delete">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  handleDelete(row) {
    confirmAlert({
      title: "Delete Support&Services File",
      message: "Are you sure to delete this Support&Services File?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { fileId: row.ID },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "Cancel",
          onClick: () => null,
        },
      ],
    });
  }

  componentDidMount() {
    this.getData();
    this.getAllTypes();
  }

  getAllTypes() {
    post(
      "/api/data/GetAllSupportServicesFilesTypesDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allTypes: apiResponse.data.respData.data,
      });
    });
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
    });

    this.getData();
  }

  getData() {
    post(
      this.state.dataUrl,
      { supportServiceId: this.state.queryString.supportServiceId },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({ tableData: apiResponse.data.respData.data });
    });
  }

  doSubmit() {
    const data = new FormData();
    if (this.state.data.file !== null) {
      data.append("File", this.state.data.file, this.state.data.file.name);
    }

    const submitData = Object.assign({}, this.state.data);

    submitData["supportServiceId"] = this.state.queryString.supportServiceId;

    data.append("requestData", JSON.stringify(submitData));

    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      data,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
        this.closeModal();
      }
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CB1B"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "dropdown",
                id: "attachType",
                label: "File Type",
                options: this.state.allTypes,
                disabled: false,
              },
              {
                type: "fileupload",
                id: "file",
                label: "File",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default Files;
