/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import GridTable from "@nadavshaar/react-grid-table";

class Clients extends Component {
  state = {
    data: {
      cleintName: "",
    },

    contentHeader: "Clients",
    addButtonLabel: "Add Client",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/GetClients",
    addUrl: "/api/data/AddClient",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "Add Client",
    modalSubmitButtonLabelAdd: "Add",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalWidth: "50vw",

    editEntityId: 0,

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "ID",
        field: "ID",
      },
      {
        id: 2,
        label: "Client",
        field: "CLINET_NAME",
      },
      {
        id: 3,
        label: "Insert Date",
        field: "INSERT_DATE",
      },
    ],
  };

  componentDidMount() {
    this.getData();
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    post(this.state.addUrl, submitData, this.props.updateIsLoading).then(
      (apiResponse) => {
        console.log(apiResponse);
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(false);
          this.closeModal();
        }
      }
    );
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CB12"]) && (
              <Button
                label={this.state.addButtonLabel}
                icon={this.state.addButtonIcon}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.setState({ inEditMode: false });
                  this.showForm();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={this.state.modalSubmitButtonLabelAdd}
                icon={this.state.modalSubmitButtonIconAdd}
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "cleintName",
                label: "Client",
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default Clients;
