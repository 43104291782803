/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { post, downloadFile } from "../../../utils/sysAxios";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import "../../../styles/table.css";
import queryString from "query-string";
import sysJwt from "../../../utils/sysJwt";
import { Dialog } from "primereact/dialog";
import SysForm from "../../commonComponents/sysForm";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { MultiSelect } from "primereact/multiselect";

class ProjecView extends Component {
  state = {
    data: {
      attachType: "",
      file: null,
    },

    projectInfo: {},
    projectNotes: [],
    projectAttachments: [],

    allAttachType: [],
    allUsers: [],

    queryString: queryString.parse(window.location.search),
    note: "",
    mention: [],

    modalWidth: "50vw",

    contentHeader:
      "Project - " + queryString.parse(window.location.search).projectId,

    dataUrl: "/api/data/GetProjectView",

    inModalModeupdate: false,
  };
  componentDidMount() {
    this.getData();
    this.getAllUsers();
    this.getAllAttachType();
  }

  getAllUsers() {
    post(
      "/api/data/GetTechniciansDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allUsers: apiResponse.data.respData.data,
      });
    });
  }

  getAllAttachType() {
    post(
      "/api/data/GetProjectAttachTypeDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allAttachType: apiResponse.data.respData.data,
      });
    });
  }

  getData() {
    post(
      this.state.dataUrl,
      { id: this.state.queryString.projectId },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({ projectInfo: apiResponse.data.respData.project });
      this.setState({ projectNotes: apiResponse.data.respData.notes });
      this.setState({
        projectAttachments: apiResponse.data.respData.projectAttachments,
      });
    });
  }

  showModalUpdate() {
    var data = this.state.data;
    data["priority"] = this.state.ticketInfo.PRIORITY;
    data["category"] = this.state.ticketInfo.CATEGORY;

    this.setState({ inModalModeUpdate: true, data: data });
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showModalUpload() {
    this.setState({ inModalModeupdate: true });
  }

  closeModalUpload() {
    this.setState({
      inModalModeupdate: false,
    });
    this.getData();
  }

  handleUpload() {
    const data = new FormData();
    if (this.state.data.file !== null) {
      data.append("File", this.state.data.file, this.state.data.file.name);
    }
    data.append(
      "requestData",
      JSON.stringify({
        projectId: this.state.queryString.projectId,
        attachType: this.state.data.attachType,
      })
    );
    post("/api/data/UploadProjectFile", data, this.props.updateIsLoading).then(
      (apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.getData();
          this.closeModalUpload();
        }
      }
    );
  }

  handleAddNote() {
    post(
      "/api/data/AddProjectNote",
      {
        projectId: this.state.queryString.projectId,
        notes: this.state.note,
        mention: this.state.mention,
      },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.setState({ note: "", mention: [] });
        this.getData();
      }
    });
  }

  handleDeleteFile(fileId) {
    confirmAlert({
      title: "Delete Project File",
      message: "Are you sure you want to delete this project file?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            post(
              "/api/data/DeleteProjectFile",
              { fileId },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              if (apiResponse.data.respCode === 0) {
                this.getData();
              }
            });
          },
        },
        {
          label: "Cancel",
          onClick: () => null,
        },
      ],
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            <h3>
              <spna>
                <i
                  className="fa fa-arrow-circle-left"
                  onClick={() => {
                    window.location.replace("/dashboard/projects");
                  }}
                ></i>
              </spna>
            </h3>
            &nbsp;&nbsp;&nbsp;&nbsp;
            {sysJwt.isAuthorized(["CB0B"]) && (
              <Button
                label="Upload Document"
                icon="fa fa-upload"
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  this.showModalUpload();
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalModeupdate}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModalUpload()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">Upload Document</h5>}
          footer={
            <div>
              <Button
                label="Upload"
                icon="fa fa-upload"
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.handleUpload()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModalUpload()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode="false"
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "dropdown",
                id: "attachType",
                label: "Attachment Type",
                options: this.state.allAttachType,
                disabled: false,
              },
              {
                type: "fileupload",
                id: "file",
                label: "File",
                options: this.state.file,
                disabled: false,
              },
            ]}
          />
        </Dialog>
        <div className="row">
          <div className="col-4">
            <h4>Project Information </h4>
            <div className="card" style={{ marginLeft: "4px" }}>
              <div className="card-body">
                <h5 className="card-title">
                  {this.state.projectInfo.PROJECT_NAME}
                </h5>
                <h6 className="card-subtitle mb-2 text-muted">
                  {this.state.projectInfo.CLIENTX}
                </h6>
                <p className="card-text">
                  <b>ID:</b> {this.state.projectInfo.ID}
                </p>
                <p className="card-text">
                  <b>Status:</b>&nbsp;
                  <span
                    style={{
                      color: "white",
                      backgroundColor: this.state.projectInfo.STATUS_COLOR,
                      "border-radius": "3px",
                    }}
                  >
                    <b>&nbsp;{this.state.projectInfo.STATUSX}&nbsp;</b>
                  </span>
                </p>
                <p className="card-text">
                  <b>Insert Date:</b> {this.state.projectInfo.INSERT_DATE}
                </p>
                <p className="card-text">
                  <b>Last Update:</b> {this.state.projectInfo.LAST_UPDATE}
                </p>
                <p className="card-text">
                  <b>Project Manager:</b>{" "}
                  {this.state.projectInfo.PROJECT_MANAGER}
                </p>
                <p className="card-text">
                  <b>Due Date:</b> {this.state.projectInfo.DUE_DATE}
                </p>
                <p className="card-text">
                  <b>Close Date:</b> {this.state.projectInfo.CLOSE_DATE}
                </p>
                {Object.entries(this.state.projectAttachments).map(
                  ([key, value]) => (
                    <div>
                      <span>{key}: </span>
                      <table style={{ width: "100%", fontSize: "12px" }}>
                        <tr style={{ border: "1px solid black" }}>
                          <th style={{ border: "1px solid black" }}>File</th>
                          <th
                            style={{ border: "1px solid black", width: "45px" }}
                          >
                            Version
                          </th>
                          <th
                            style={{
                              border: "1px solid black",
                              width: "125px",
                            }}
                          >
                            Insert Date
                          </th>
                          <th></th>
                        </tr>
                        {JSON.parse(value).map((member) => (
                          <tr style={{ border: "1px solid black" }}>
                            <td style={{ border: "1px solid black" }}>
                              <a
                                title={member.FILE_NAME}
                                style={{
                                  color: "black",
                                  fontSize: "12px",
                                  cursor: "pointer",
                                }}
                                className="underline-on-hover"
                                onClick={() => {
                                  downloadFile(
                                    member.FILE_PATH,
                                    this.props.updateIsLoading
                                  );
                                }}
                              >
                                {member.FILE_NAME}
                              </a>
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                width: "45px",
                              }}
                            >
                              {member.VERSION}
                            </td>
                            <td
                              style={{
                                border: "1px solid black",
                                width: "125px",
                              }}
                            >
                              {member.INSERT_DATE}
                            </td>
                            {sysJwt.isAuthorized(["CB15"]) && (
                              <td>
                                <i
                                  className="fa fa-trash"
                                  title="Delete File"
                                  onClick={() => {
                                    this.handleDeleteFile(member.ID);
                                  }}
                                />
                              </td>
                            )}
                          </tr>
                        ))}
                      </table>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          <div className="col-8">
            <div className="row">
              <div className="col-9">
                <h4>Project Notes</h4>
              </div>
            </div>

            {sysJwt.isAuthorized(["CB0A"]) && (
              <div className="card border-secondary">
                <div className="row">
                  <div className="col-12">
                    <ReactQuill
                      theme="snow"
                      value={this.state.note}
                      onChange={(e) => {
                        this.setState({ note: e });
                      }}
                    />
                  </div>
                </div>
                <div className="row">
                  <div>Mention:</div>
                  <div className="col-12">
                    <MultiSelect
                      value={this.state.mention}
                      showClear={true}
                      options={this.state.allUsers}
                      filter={true}
                      className="dropdown"
                      onChange={(e) => {
                        this.setState({ mention: e.target.value });
                      }}
                    />
                  </div>
                  <div className="row">
                    <div
                      className="col-2"
                      style={{ paddingBottom: "2px", "padding-top": "2px" }}
                    >
                      <Button
                        label="Add"
                        className="p-button-rounded p-button-secondary"
                        icon="fa fa-plus"
                        disabled={this.props.isLoading}
                        onClick={() => {
                          this.handleAddNote();
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            <hr />
            {this.state.projectNotes.map((member) => (
              <div
                className="card border-secondary"
                style={{ "margin-bottom": "5px" }}
              >
                <div className="card-header">
                  <div className="row">
                    <div className="col-11">{member.FROMX}</div>
                  </div>
                </div>
                <div className="card-body">
                  <p className="card-title" style={{ fontSize: 11 }}>
                    {member.INSERT_DATE}
                  </p>
                  <p
                    className="card-text"
                    dangerouslySetInnerHTML={{
                      __html: member.CONTENT,
                    }}
                  ></p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default ProjecView;
