import React, { Component } from "react";
import homelogo from "../../images/homeimg.jpg";
import { Chart } from "primereact/chart";
import { post } from "../../utils/sysAxios";
import "../../styles/charts.css";

class Home extends Component {
  state = {
    openTicketStatus: null,
    allTicketLast7DaysStatus: null,
    userSummary: null,
    last10DayTickets: null,
  };

  componentDidMount() {
    this.getData();
  }

  getData() {
    post("/api/data/GetHomeCharts", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        console.log(apiResponse.data.respData);
        this.setState({
          openTicketStatus: apiResponse.data.respData.openTicketStatus,
          allTicketLast7DaysStatus:
            apiResponse.data.respData.allTicketLast7DaysStatus,
          userSummary: apiResponse.data.respData.userSummary,
          last10DayTickets: apiResponse.data.respData.last10DayTickets,
        });
      }
    );
  }

  render() {
    return (
      <React.Fragment>
        {this.state.openTicketStatus ? (
          <div className="charts-container fade-in">
            <div className="home-charts-row">
              <div
                className="card"
                style={{
                  backgroundColor: "#E9E9E9",
                  height: "320px",
                  paddingRight: "20px",
                }}
              >
                <h4>Your Tickets Summary</h4>
                <br />
                <div
                  style={{
                    paddingLeft: "10px",
                  }}
                >
                  <p>
                    Opned Tickets: <b>{this.state.userSummary.openTickets}</b>
                  </p>
                  <p>
                    Overdue Tickets:{" "}
                    <b>{this.state.userSummary.overdueTickets}</b>
                  </p>
                  <p>
                    Last 7 days Tickets:{" "}
                    <b>{this.state.userSummary.last7DaysTickets}</b>
                  </p>
                  <p>
                    Last 7 days Resolved Tickets:{" "}
                    <b>{this.state.userSummary.last7DaysResolvedTickets}</b>
                  </p>
                </div>
              </div>
              <div className="card" style={{ backgroundColor: "#E9E9E9" }}>
                <Chart
                  type="doughnut"
                  height="320px"
                  data={{
                    labels: this.state.openTicketStatus.map((e) => e.KEY),
                    datasets: [
                      {
                        data: this.state.openTicketStatus.map((e) => e.VALUE),
                        backgroundColor: this.state.openTicketStatus.map(
                          (e) => e.COLOR
                        ),
                      },
                    ],
                  }}
                  options={{
                    plugins: {
                      title: {
                        display: true,
                        text: "All Opened Tickets",
                      },
                    },
                  }}
                />
              </div>
              <div className="card" style={{ backgroundColor: "#E9E9E9" }}>
                {this.state.openTicketStatus ? (
                  <Chart
                    type="pie"
                    height="320px"
                    data={{
                      labels: this.state.allTicketLast7DaysStatus.map(
                        (e) => e.KEY
                      ),
                      datasets: [
                        {
                          data: this.state.allTicketLast7DaysStatus.map(
                            (e) => e.VALUE
                          ),
                          backgroundColor:
                            this.state.allTicketLast7DaysStatus.map(
                              (e) => e.COLOR
                            ),
                        },
                      ],
                    }}
                    options={{
                      plugins: {
                        title: {
                          display: true,
                          text: "Last 7 Days Tickets",
                        },
                      },
                    }}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
            <br />
            <div className="home-charts-row">
              <div
                className="card card--bar"
                style={{ backgroundColor: "#E9E9E9" }}
              >
                <Chart
                  type="line"
                  height="320px"
                  data={{
                    labels: this.state.last10DayTickets.map((e) => e.KEY),
                    datasets: [
                      {
                        label: "Number of Tickets",
                        data: this.state.last10DayTickets.map((e) => e.VALUE),
                        backgroundColor: "#5B32F2",
                      },
                    ],
                  }}
                  options={{
                    maintainAspectRatio: false,
                    responsive: true,
                    scales: {
                      y: {
                        position: "left",
                      },
                    },
                    plugins: {
                      title: {
                        display: true,
                        text: "Last 10 Days Tickets",
                      },
                    },
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="home-logo text-center">
            <img src={homelogo} width={1000} height={500} alt="HOME" />
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default Home;
