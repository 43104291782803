/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import sysJwt from "../../../utils/sysJwt";
import SysForm from "../../commonComponents/sysForm";
import { post } from "../../../utils/sysAxios";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { confirmAlert } from "react-confirm-alert";
import { apiDataToDropdownList } from "../../../utils/sysHelpers";
import GridTable from "@nadavshaar/react-grid-table";
import ReactQuill from "react-quill";

class UserManagement extends Component {
  state = {
    data: {
      Username: "",
      FirstName: "",
      LastName: "",
      UserRole: "",
      UserType: "",
      Signature: "",
      userGroups: [],
    },

    contentHeader: "Users",
    addButtonLabel: "Add user",
    addButtonIcon: "fa fa-plus",

    dataUrl: "/api/data/getusers",
    singleItemUrl: "/api/data/getuser",
    addUrl: "/api/data/adduser",
    editUrl: "/api/data/edituser",
    deleteUrl: "/api/data/deleteuser",

    inEditMode: false,
    inModalMode: false,
    modalHeaderAdd: "Add User",
    modalSubmitButtonLabelAdd: "Add",
    modalSubmitButtonIconAdd: "fa fa-plus",
    modalHeaderEdit: "Edit User",
    modalSubmitButtonLabelEdit: "Edit",
    modalSubmitButtonIconEdit: "fa fa-edit",
    modalWidth: "50vw",

    editEntityId: 0,

    rolesDropdown: [],
    allUserTypes: [],
    allGroups: [],

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "Username",
        field: "USERNAME",
      },
      {
        id: 2,
        label: "Is Blocked",
        field: "IS_BLOCKED",
        width: "110px",
        cellRenderer: ({ data }) => {
          return data.IS_BLOCKED === "Y" ? "Yes" : "No";
        },
      },
      {
        id: 3,
        label: "Insert Date",
        field: "INSERT_DATE",
      },
      {
        id: 4,
        label: "First Name",
        field: "FIRST_NAME",
      },
      {
        id: 5,
        label: "Last Name",
        field: "LAST_NAME",
      },
      {
        id: 6,
        label: "Role",
        field: "ROLE_NAME",
        width: "110px",
      },
      {
        id: 7,
        label: "User Type",
        field: "USER_TYPE",
      },
      {
        id: 8,
        label: "Last Login",
        field: "LAST_LOGIN_DATE",
      },
      {
        id: 9,
        label: "Failed Login Counter",
        field: "FAILED_LOGIN_COUNTER",
      },
      {
        id: 10,
        label: "Is Default Password",
        field: "IS_DEFAULT_PWD",
      },
      {
        id: 11,
        label: "Actions",
        field: "SYS_ACTIONS",
        width: "120px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              {sysJwt.isAuthorized(["CAC0"]) && (
                <div className="inner" title="Edit">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.getEditFormData(data).then(() => {
                        this.setState({
                          inEditMode: true,
                        });
                        this.showForm();
                      });
                    }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CAC1"]) && (
                <div className="inner" title="Delete">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleDelete(data)}
                  >
                    <i className="fa fa-trash" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CAC2"]) && (
                <div className="inner" title="Block">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleBlock(data)}
                  >
                    <i className="fa fa-ban" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CAC3"]) && (
                <div className="inner" title="Unblock">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleUnblock(data)}
                  >
                    <i className="fa fa-check" />
                  </button>
                </div>
              )}
              {sysJwt.isAuthorized(["CAC4"]) && (
                <div className="inner" title="Reset Password">
                  <button
                    className="dropdown-item btn-popup-menu"
                    disabled={this.props.isLoading}
                    onClick={() => this.handleResetPassword(data)}
                  >
                    <i className="fa fa-key" />
                  </button>
                </div>
              )}
            </div>
          );
        },
      },
    ],
  };

  componentDidMount() {
    this.getData();
    this.getUserTypesDropdown();
    this.getAllGroup();
  }

  getAllGroup() {
    post(
      "/api/data/GetAllGroupsDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allGroups: apiResponse.data.respData.data,
      });
    });
  }

  updateForm = (inputId, inputValue) => {
    var data = this.state.data;
    data[inputId] = inputValue;
    this.setState({ data });
  };

  showForm() {
    this.setState({ inModalMode: true });

    this.getRolesDropdown();
  }

  closeModal() {
    var data = this.state.data;

    Object.keys(data).forEach(function (key) {
      Array.isArray(data[key]) ? (data[key] = []) : (data[key] = "");
    });

    this.setState({
      inModalMode: false,
      inEditMode: false,
      data: data,
      editEntityId: 0,
      rolesDropdown: [],
    });

    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  getEditFormData(row) {
    return post(
      this.state.singleItemUrl,
      { UserId: row.ID },
      this.props.updateIsLoading
    ).then((apiResponse) => {
      var data = this.state.data;

      data["Username"] = apiResponse.data.respData.data[0].USERNAME;
      data["FirstName"] = apiResponse.data.respData.data[0].FIRST_NAME;
      data["LastName"] = apiResponse.data.respData.data[0].LAST_NAME;
      data["UserRole"] = apiResponse.data.respData.data[0].ROLE_ID_FK;
      data["UserType"] = apiResponse.data.respData.data[0].USER_TYPE;
      data["Signature"] = apiResponse.data.respData.data[0].SIGNATURE;

      data["userGroups"] = [];
      for (var i = 0; i < apiResponse.data.respData.userGroups.length; i++) {
        data["userGroups"].push(
          apiResponse.data.respData.userGroups[i].GROUP_ID
        );
      }

      this.setState({
        data: data,
        editEntityId: apiResponse.data.respData.data[0].ID,
      });
    });
  }

  getRolesDropdown() {
    post("/api/data/getrolesdropdown", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({
          rolesDropdown: apiDataToDropdownList(
            apiResponse.data.respData.data,
            "ROLE_NAME",
            "ID"
          ),
        });
      }
    );
  }

  getUserTypesDropdown() {
    post(
      "/api/data/GetAllUserTypesDropdown",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allUserTypes: apiResponse.data.respData.data,
      });
    });
  }

  handleDelete(row) {
    confirmAlert({
      title: "Delete User",
      message: "Are you sure to delete this user?",
      buttons: [
        {
          label: "Yes",
          onClick: () => {
            post(
              this.state.deleteUrl,
              { UserId: row.ID, Username: row.USERNAME },
              this.props.updateIsLoading
            ).then((apiResponse) => {
              apiResponse.data.respCode === 0 ? this.getData() : null;
            });
          },
        },
        {
          label: "Cancel",
          onClick: () => null,
        },
      ],
    });
  }

  handleBlock(row) {
    post(
      "/api/data/blockuser",
      { UserId: row.ID, Username: row.USERNAME },
      this.props.updateIsLoading
    ).then(() => this.getData());
  }

  handleUnblock(row) {
    post(
      "/api/data/unblockuser",
      { UserId: row.ID, Username: row.USERNAME },
      this.props.updateIsLoading
    ).then(() => this.getData());
  }

  handleResetPassword(row) {
    post(
      "/api/data/resetpassword",
      { UserId: row.ID, Username: row.USERNAME },
      this.props.updateIsLoading
    ).then(() => this.getData());
  }

  doSubmit() {
    const submitData = Object.assign({}, this.state.data);

    if (this.state.inEditMode) {
      submitData["EditEntityId"] = this.state.editEntityId;
    }

    post(
      this.state.inEditMode ? this.state.editUrl : this.state.addUrl,
      submitData,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      if (apiResponse.data.respCode === 0) {
        this.props.updateIsLoading(false);
      }
    });
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            <Button
              label={this.state.addButtonLabel}
              icon={this.state.addButtonIcon}
              className="p-button-rounded p-button-secondary"
              disabled={this.props.isLoading}
              onClick={() => {
                this.setState({ inEditMode: false });
                this.showForm();
              }}
            />
          </div>
        </div>
        <hr />
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={
            <h5 className="modal-header">
              {this.state.inEditMode
                ? this.state.modalHeaderEdit
                : this.state.modalHeaderAdd}
            </h5>
          }
          footer={
            <div>
              <Button
                label={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonLabelEdit
                    : this.state.modalSubmitButtonLabelAdd
                }
                icon={
                  this.state.inEditMode
                    ? this.state.modalSubmitButtonIconEdit
                    : this.state.modalSubmitButtonIconAdd
                }
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => this.doSubmit()}
              />
              <Button
                label="Cancel"
                icon="fa fa-close"
                className="p-button-rounded p-button-danger"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <SysForm
            isLoading={this.props.isLoading}
            inEditMode={this.state.inEditMode}
            data={this.state.data}
            updateForm={this.updateForm}
            inputs={[
              {
                type: "text",
                id: "Username",
                label: "Username",
                disabled: this.state.inEditMode ? true : false,
              },
              {
                type: "text",
                id: "FirstName",
                label: "First Name",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "UserRole",
                label: "Role",
                options: this.state.rolesDropdown,
                disabled: false,
              },
              {
                type: "text",
                id: "LastName",
                label: "Last Name",
                disabled: false,
              },
              {
                type: "dropdown",
                id: "UserType",
                label: "User Type",
                options: this.state.allUserTypes,
                disabled: false,
              },
              {
                type: "multiselect",
                id: "userGroups",
                label: "User Groups",
                options: this.state.allGroups,
                disabled: false,
              },
            ]}
          />
          <label>Signature</label>
          <ReactQuill
            theme="snow"
            value={this.state.data.Signature}
            onChange={(e) => {
              var data = this.state.data;

              data["Signature"] = e;

              this.setState({
                data: data,
              });
            }}
          />
        </Dialog>
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default UserManagement;
