import React, { Component } from "react";
import logo from "../../images/homeimg.jpg";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { post } from "../../utils/sysAxios";

class ApplicationInfo extends Component {
  state = {
    inModalMode: true,
    modalHeader: "About System",
    modalSubmitButtonLabel: "Ok",
    modalSubmitButtonIcon: "fa fa-check",
    modalWidth: "35vw",
    version: "",
  };

  componentDidMount() {
    this.setState({ inModalMode: this.props.inModalMode });

    post("/api/system/getversion", null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ version: apiResponse.data.respData.AppVersion });
      }
    );
  }

  closeModal() {
    this.setState({ inModalMode: false });
    window.location.replace("/dashboard/home");
  }

  render() {
    return (
      <React.Fragment>
        <div className="aps-logo text-center">
          <img src={logo} width={1000} height={500} alt="System Logo" />
        </div>
        <Dialog
          visible={this.state.inModalMode}
          style={{ width: this.state.modalWidth }}
          onHide={(e) => this.closeModal()}
          dismissableMask={true}
          closable={false}
          header={<h5 className="modal-header">{this.state.modalHeader}</h5>}
          footer={
            <div>
              <Button
                label={this.state.modalSubmitButtonLabel}
                icon={this.state.modalSubmitButtonIcon}
                className="p-button-rounded p-button-secondary"
                onClick={() => this.closeModal()}
              />
            </div>
          }
        >
          <div className="app-info">
          Ticketing Management System
            <br /> Version: {this.state.version}
            <br /> [{new Date().getFullYear()}]&nbsp;
            <a
              href="https://www.midisgroup.com/affiliates/infotech-business-solutions-sal/"
              style={{ color: "blue", display: "inline-block" }}
              target="blank"
            >
              InfoTech
            </a>
            <span>&nbsp;All rights reserved for</span>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

export default ApplicationInfo;
