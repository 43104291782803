class sysJwt {
  static getUserId() {
    return this.getUserInfo()["117115101114105100"];
  }

  static getUsername() {
    return this.getUserInfo()["11711510111411097109101"];
  }

  static getRoleId() {
    return this.getUserInfo()["114111108101105100"];
  }

  static getClaimsCacheId() {
    return this.getUserInfo()["999799104101105100"];
  }

  static getIsDefaultPwd() {
    if (this.getUserInfo()["100102112"]) {
      return this.getUserInfo()["100102112"];
    } else {
      return "";
    }
  }

  static getUserInfo = () => {
    try {
      return JSON.parse(atob(sessionStorage.getItem("748784").split(".")[1]));
    } catch (ex) {
      sessionStorage.removeItem("748784");
      window.location.replace("/login");
    }
  };

  static isAuthorized(toBeAuthorizedClaims) {
    var isAuthorized = true;

    let claims = this.getUserInfo()["9910897105109115"].split(",");

    for (var i = 0; i < toBeAuthorizedClaims.length; i++) {
      if (claims.includes(toBeAuthorizedClaims[i])) {
        continue;
      } else {
        isAuthorized = false;
        break;
      }
    }

    return isAuthorized;
  }
}

export default sysJwt;
