import React from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from "react-pro-sidebar";
import sidebarBg from "../../images/bgSide.jpg";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import {
  FaUsers,
  FaChartArea,
  FaServer,
  FaUserCheck,
  FaWhmcs,
  FaUserShield,
  FaInfo,
  FaPowerOff,
  FaTicketAlt,
  FaThList,
  FaRegHandPaper,
  FaGripHorizontal,
  FaIdBadge,
  FaNetworkWired,
  FaProjectDiagram,
  FaSquarespace,
  FaRulerHorizontal,
  FaIndent,
  FaUsersCog,
  FaServicestack,
} from "react-icons/fa";
import "../../styles/App.scss";
import sysJwt from "../../utils/sysJwt";

const Aside = ({ username, isLoading, handleLogout }) => {
  const [toggled, setToggled] = useState(false);

  const handleClick = (event) => {
    setToggled(!toggled);
  };

  return (
    <div className={`app ${1 === 1 ? "toggled" : ""}`}>
      <ProSidebar
        image={sidebarBg}
        collapsed={toggled}
        toggled={true}
        breakPoint="md"
        width={230}
      >
        <SidebarHeader>
          <div
            style={{
              padding: "24px",
              textTransform: "uppercase",
              fontWeight: "bold",
              fontSize: 14,
              letterSpacing: "1px",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {!isLoading ? (
              <i className="fa fa-bars" onClick={handleClick} />
            ) : (
              ""
            )}
            {isLoading ? (
              <Bars color="white" height="25" width="30" />
            ) : (
              <a href="/dashboard/home">Dashboard</a>
            )}
          </div>
        </SidebarHeader>

        <SidebarContent>
          <Menu iconShape="circle">
            {sysJwt.isAuthorized(["CABD"]) && (
              <SubMenu icon={<FaWhmcs />} title="System">
                {sysJwt.isAuthorized(["CABD", "CABE"]) && (
                  <MenuItem icon={<FaUsers />}>
                    <Link to="/dashboard/usermanagement">Users</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CABD", "CAC5"]) && (
                  <MenuItem icon={<FaUserShield />}>
                    <Link to="/dashboard/rolemanagement">Roles</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CABD", "CAD5"]) && (
                  <MenuItem icon={<FaServer />}>
                    <Link to="/dashboard/systemparameters">
                      System Parameters
                    </Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CABD", "CAF6"]) && (
                  <MenuItem icon={<FaGripHorizontal />}>
                    <Link to="/dashboard/categories">Categories</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CABD", "CB03"]) && (
                  <MenuItem icon={<FaNetworkWired />}>
                    <Link to="/dashboard/groups">Groups</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CABD", "CB11"]) && (
                  <MenuItem icon={<FaUsersCog />}>
                    <Link to="/dashboard/clients">Clients</Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {sysJwt.isAuthorized(["CAEF"]) && (
              <SubMenu icon={<FaTicketAlt />} title="Tickets">
                {sysJwt.isAuthorized(["CAF0"]) && (
                  <MenuItem icon={<FaThList />}>
                    <Link to="/dashboard/alltickets">All Tickets</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CAF1"]) && (
                  <MenuItem icon={<FaRegHandPaper />}>
                    <Link to="/dashboard/mytickets">My Tickets</Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {sysJwt.isAuthorized(["CB06"]) && (
              <SubMenu icon={<FaProjectDiagram />} title="Projects">
                {sysJwt.isAuthorized(["CB07"]) && (
                  <MenuItem icon={<FaSquarespace />}>
                    <Link to="/dashboard/projects">All Projects</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CB0C"]) && (
                  <MenuItem icon={<FaRulerHorizontal />}>
                    <Link to="/dashboard/attachtype">Attachment Types</Link>
                  </MenuItem>
                )}
                {sysJwt.isAuthorized(["CB0E"]) && (
                  <MenuItem icon={<FaIndent />}>
                    <Link to="/dashboard/projectstatus">Status</Link>
                  </MenuItem>
                )}
              </SubMenu>
            )}
            {sysJwt.isAuthorized(["CB17"]) && (
              <MenuItem icon={<FaServicestack />}>
                Support&Services <Link to="/dashboard/supportservices" />
              </MenuItem>
            )}
            {sysJwt.isAuthorized(["CAFC"]) && (
              <MenuItem icon={<FaChartArea />}>
                Reports <Link to="/dashboard/reports" />
              </MenuItem>
            )}
            <MenuItem icon={<FaInfo />}>
              <Link to="/dashboard/applicationinfo">About System</Link>
            </MenuItem>
            <MenuItem icon={<FaIdBadge />}>
              <Link to="/dashboard/userprofile">Profile</Link>
            </MenuItem>
            <MenuItem icon={<FaPowerOff />}>
              <a onClick={() => handleLogout()}>Logout</a>
            </MenuItem>
          </Menu>
        </SidebarContent>

        <SidebarFooter style={{ textAlign: "center" }}>
          <div
            className="sidebar-btn-wrapper"
            style={{
              padding: "20px 24px",
            }}
          >
            <a className="sidebar-btn" rel="noopener noreferrer">
              <FaUserCheck />
              <span
                style={{
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "hidden",
                }}
              >
                {username}
              </span>
            </a>
          </div>
        </SidebarFooter>
      </ProSidebar>
    </div>
  );
};

export default Aside;
