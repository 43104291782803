import React, { Component } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Home from "./home";
import ChangePassword from "./changePassword";
import Notfound from "./notfound";
import ApplicationInfo from "../mainComponents/applicationInfo";
import UserProfile from "../mainComponents/userProfile";
import UserManagement from "../contentComponents/system/usermanagement";
import RoleManagement from "../contentComponents/system/rolemanagement";
import SystemParameters from "../contentComponents/system/systemparameters";
import sysJwt from "../../utils/sysJwt";
import Reports from "../contentComponents/reports/reports";
import ExecuteReport from "../contentComponents/reports/executeReport";
import AllTickets from "../contentComponents/tickets/allTickets";
import TicketView from "../contentComponents/tickets/ticketView";
import MyTickets from "../contentComponents/tickets/myTickets";
import Categories from "../contentComponents/system/category";
import NewTicket from "../contentComponents/tickets/newTicket";
import Groups from "../contentComponents/system/group";
import Projects from "../contentComponents/projects/projects";
import ProjectView from "../contentComponents/projects/projectView";
import AttachTypes from "../contentComponents/projects/attachtypes";
import ProjectStatus from "../contentComponents/projects/status";
import Clients from "../contentComponents/system/clients";
import SupportServices from "../contentComponents/supportServices/suppotServices";
import SupportServicesFiles from "../contentComponents/supportServices/files";

class AppRoutes extends Component {
  state = {};
  render() {
    return (
      <Routes>
        {sysJwt.isAuthorized(["CAFC"]) && (
          <Route
            path="/reports"
            element={
              <Reports
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAFC", "CAFD"]) && (
          <Route
            path="/executereport"
            element={
              <ExecuteReport
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CABE"]) && (
          <Route
            path="/usermanagement"
            element={
              <UserManagement
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAC5"]) && (
          <Route
            path="/rolemanagement"
            element={
              <RoleManagement
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAD5"]) && (
          <Route
            path="/systemparameters"
            element={
              <SystemParameters
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAF0"]) && (
          <Route
            path="/alltickets"
            element={
              <AllTickets
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        <Route
          path="/ticketview"
          element={
            <TicketView
              inModalMode={false}
              isLoading={this.props.isLoading}
              updateIsLoading={this.props.updateIsLoading}
            />
          }
        />
        {sysJwt.isAuthorized(["CAF1"]) && (
          <Route
            path="/mytickets"
            element={
              <MyTickets
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAF6"]) && (
          <Route
            path="/categories"
            element={
              <Categories
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CAF9"]) && (
          <Route
            path="/newticket"
            element={
              <NewTicket
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB03"]) && (
          <Route
            path="/groups"
            element={
              <Groups
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB07"]) && (
          <Route
            path="/projects"
            element={
              <Projects
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB07"]) && (
          <Route
            path="/projectview"
            element={
              <ProjectView
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB0C"]) && (
          <Route
            path="/attachtype"
            element={
              <AttachTypes
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB0E"]) && (
          <Route
            path="/projectstatus"
            element={
              <ProjectStatus
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB11"]) && (
          <Route
            path="/clients"
            element={
              <Clients
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB17"]) && (
          <Route
            path="/supportservices"
            element={
              <SupportServices
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        {sysJwt.isAuthorized(["CB17"]) && (
          <Route
            path="/supportservicesfile"
            element={
              <SupportServicesFiles
                inModalMode={false}
                isLoading={this.props.isLoading}
                updateIsLoading={this.props.updateIsLoading}
              />
            }
          />
        )}
        <Route
          path="/applicationinfo"
          element={
            <ApplicationInfo
              inModalMode={true}
              isLoading={this.props.isLoading}
              updateIsLoading={this.props.updateIsLoading}
            />
          }
        />
        <Route
          path="/changepassword"
          element={
            <ChangePassword
              inModalMode={true}
              isLoading={this.props.isLoading}
              updateIsLoading={this.props.updateIsLoading}
            />
          }
        />
        <Route
          path="/userprofile"
          element={
            <UserProfile
              inModalMode={true}
              isLoading={this.props.isLoading}
              updateIsLoading={this.props.updateIsLoading}
            />
          }
        />
        <Route
          path="/home"
          element={<Home updateIsLoading={this.props.updateIsLoading} />}
        />
        <Route path="/notfound" element={<Notfound />} />
        <Route
          path="*"
          element={<Navigate to="/dashboard/notfound" replace />}
        />
      </Routes>
    );
  }
}

export default AppRoutes;
