/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { post } from "../../../utils/sysAxios";
import { Button } from "primereact/button";
import "../../../styles/table.css";
import queryString from "query-string";
import sysJwt from "../../../utils/sysJwt";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

class NewTicket extends Component {
  state = {
    data: {},

    files: "",

    contentHeader: "New Ticket",

    dataUrl: "/api/data/getticketinfo",

    queryString: queryString.parse(window.location.search),

    subject: "",
    from: "",
    group: "",
    category: "",
    priority: "",
    content: "",
    client: "",
    cc: "",
    assignToMe: "",

    allCategories: [],
    allPriorities: [],
    allGroups: [],
    allClients: [],
    addressBook: null,
  };
  componentDidMount() {
    this.getAllDropdown();
  }

  getAllDropdown() {
    post(
      "/api/data/GetAllDropdownNewTicket",
      null,
      this.props.updateIsLoading
    ).then((apiResponse) => {
      this.setState({
        allGroups: apiResponse.data.respData.groups,
        allCategories: apiResponse.data.respData.categories,
        allPriorities: apiResponse.data.respData.priorities,
        allClients: apiResponse.data.respData.clients,
        addressBook: apiResponse.data.respData.addressBook,
      });
      console.log(apiResponse.data.respData.addressBook);
    });
  }

  sleep(time) {
    return new Promise((resolve) => setTimeout(resolve, time));
  }

  handleAddSubmit() {
    const data = new FormData();

    if (this.state.files) {
      Array.from(this.state.files).map((member) =>
        data.append("files", member, member.name)
      );
    }

    data.append(
      "requestData",
      JSON.stringify({
        subject: this.state.subject,
        from: this.state.from,
        category: this.state.category,
        priority: this.state.priority,
        content: this.state.content,
        groupId: this.state.group,
        client: this.state.client,
        cc: this.state.cc,
        assignToMe: this.state.assignToMe,
      })
    );
    post("/api/data/createticket", data, this.props.updateIsLoading).then(
      (apiResponse) => {
        if (apiResponse.data.respCode === 0) {
          this.props.updateIsLoading(true);
          this.sleep(2000).then(() =>
            window.location.replace(
              "/dashboard/" + this.state.queryString.source
            )
          );
        }
      }
    );
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            <h3>
              <spna>
                <i
                  className="fa fa-arrow-circle-left"
                  onClick={() => {
                    window.location.replace(
                      "/dashboard/" + this.state.queryString.source
                    );
                  }}
                ></i>
              </spna>
            </h3>
          </div>
        </div>
        <hr />
        <div className="row" style={{ marginLeft: "2px" }}>
          <div className="col-12">
            <div className="card border-secondary">
              <div className="row paddingRowClass">
                <div className="col-6">
                  <label>Subject</label>
                  <InputText
                    className="form-control form-control-sm"
                    value={this.state.subject}
                    onChange={(e) => {
                      this.setState({ subject: e.target.value });
                    }}
                  />
                </div>
                <div className="col-6">
                  <label>From</label>
                  <InputText
                    list="addressbooklist"
                    value={this.state.from}
                    disabled={sysJwt.isAuthorized(["CAFA"]) ? false : true}
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      this.setState({ from: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="row paddingRowClass">
                <div className="col-12">
                  <label>CC</label>
                  <InputText
                    list="addressbooklist"
                    value={this.state.cc}
                    showClear={true}
                    disabled={sysJwt.isAuthorized(["CAFA"]) ? false : true}
                    className="form-control form-control-sm"
                    title="List of emails separated by comma"
                    onChange={(e) => {
                      this.setState({ cc: e.target.value });
                    }}
                  />
                </div>
              </div>
              <div className="row paddingRowClass">
                <div className="col-3">
                  <label>Client</label>
                  <Dropdown
                    value={this.state.client}
                    showClear={true}
                    options={this.state.allClients}
                    disabled={false}
                    filter={true}
                    className="dropdown"
                    onChange={(e) => {
                      this.setState({ client: e.target.value });
                    }}
                  />
                </div>
                <div className="col-3">
                  <label>Group</label>
                  <Dropdown
                    value={this.state.group}
                    showClear={true}
                    options={this.state.allGroups}
                    disabled={false}
                    filter={true}
                    className="dropdown"
                    onChange={(e) => {
                      this.setState({ group: e.target.value });
                    }}
                  />
                </div>
                <div className="col-3">
                  <label>Category</label>
                  <Dropdown
                    value={this.state.category}
                    showClear={true}
                    options={this.state.allCategories}
                    disabled={false}
                    filter={true}
                    className="dropdown"
                    onChange={(e) => {
                      this.setState({ category: e.target.value });
                    }}
                  />
                </div>
                <div className="col-3">
                  <label>Priority</label>
                  <Dropdown
                    value={this.state.priority}
                    showClear={true}
                    options={this.state.allPriorities}
                    disabled={false}
                    filter={true}
                    className="dropdown"
                    onChange={(e) => {
                      this.setState({ priority: e.target.value });
                    }}
                  />
                </div>
              </div>
              {sysJwt.isAuthorized(["CAFA"]) ? (
                <div className="row paddingRowClass">
                  <div className="col-10">
                    <label>Assign To Me&nbsp;&nbsp;</label>
                    <input
                      type="checkbox"
                      value={this.state.assignToMe}
                      onChange={(e) => {
                        this.setState({ assignToMe: e.target.checked });
                      }}
                      style={{ paddingTop: "10px" }}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="row paddingRowClass">
                <div className="col-12">
                  <label>Content</label>
                  <ReactQuill
                    theme="snow"
                    value={this.state.content}
                    onChange={(e) => {
                      this.setState({ content: e });
                    }}
                  />
                </div>
              </div>
              <div className="row paddingRowClass">
                <div className="col-10" style={{ paddingTop: "5px" }}>
                  <input
                    type="file"
                    multiple="multiple"
                    onChange={(e) => {
                      this.setState({ files: e.target.files });
                    }}
                  ></input>
                </div>
                <div
                  className="col-2"
                  style={{ paddingBottom: "2px", paddingTop: "2px" }}
                >
                  <Button
                    label="Submit"
                    className="p-button-rounded p-button-secondary"
                    icon="fa fa-location-arrow"
                    disabled={this.props.isLoading}
                    onClick={() => {
                      this.handleAddSubmit();
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <datalist id="addressbooklist">
          {this.state.addressBook
            ? this.state.addressBook.map((member) => (
                <option value={member.Key} />
              ))
            : ""}
        </datalist>
      </div>
    );
  }
}

export default NewTicket;
