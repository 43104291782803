export function apiDataToDropdownList(apiData, label, value) {
  var options = [];

  for (var i = 0; i < apiData.length; i++) {
    var option = {
      label: apiData[i][label],
      value: apiData[i][value],
    };
    options.push(option);
  }

  return options;
}

export function getStatusColor(status) {
  switch (status) {
    case "New":
      return "#6C757D";
    case "In Progress":
      return "#FFB200";
    case "Pending":
      return "#F57F17";
    case "Resolved":
      return "#28A745";
    case "Rejected":
      return "#DC3545";
    case "Closed":
      return "#343A40";
    case "Duplicated":
      return "#FF5C76";
    default:
      return "#C0C0C0";
  }
}
