import { toast } from "react-toastify";

export function toastError(errorMessage) {
  toast.error(errorMessage, {
    autoClose: 5000,
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "error-toast bms-toast",
  });
}

export function toastSuccess(successMessage) {
  toast.info(successMessage, {
    autoClose: 5000,
    position: toast.POSITION.BOTTOM_RIGHT,
    className: "info-toast bms-toast",
  });
}
