/* eslint-disable no-unused-expressions */

import React, { Component } from "react";
import { post } from "../../../utils/sysAxios";
import { confirmAlert } from "react-confirm-alert";
import GridTable from "@nadavshaar/react-grid-table";
import InfoPopUp from "../../commonComponents/infoPopUp";
import sysJwt from "../../../utils/sysJwt";
import { Button } from "primereact/button";
import { getStatusColor } from "../../../utils/sysHelpers";

class MyTickets extends Component {
  state = {
    data: {
      appoId: "",
      patientName: "",
      drName: "",
      statusId: "",
    },

    dates: "",
    instId: "",
    smsId: "",
    recipient: "",

    contentHeader: "My Tickets",

    dataUrl: "/api/data/getmytickets",

    tableData: [],
    tableColumns: [
      {
        id: 1,
        label: "ID",
        field: "ID",
        width: "100px",
        resizable: false,
      },
      {
        id: 2,
        label: "Insert Date",
        field: "INSERT_DATE",
      },
      {
        id: 3,
        label: "Group",
        field: "GROUP_NAME",
        width: "85px",
      },
      {
        id: 4,
        label: "From",
        field: "FROMX",
        width: "280px",
      },
      {
        id: 5,
        label: "Client",
        field: "CLIENTX",
        width: "150px",
      },
      {
        id: 6,
        label: "Subject",
        field: "SUBJECTX",
      },
      {
        id: 7,
        label: "Status",
        field: "STATUSX",
        width: "110px",
        resizable: false,
        cellRenderer: ({ data }) => {
          return (
            <span
              style={{
                color: "white",
                backgroundColor: getStatusColor(data.STATUSX),
                "border-radius": "3px",
              }}
            >
              <b>&nbsp;{data.STATUSX}&nbsp;</b>
            </span>
          );
        },
      },
      {
        id: 8,
        label: "Assugned To",
        field: "ASSIGNED_TO",
      },
      {
        id: 9,
        label: "Actions",
        field: "SYS_ACTINOS",
        width: "100px",
        maxResizeWidth: "100px",
        resizable: false,
        pinned: true,
        cellRenderer: ({ data }) => {
          return (
            <div id="outer">
              <div className="inner" title="More Info">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    this.handleMoreInfo(data);
                  }}
                >
                  <i className="fa fa-info" />
                </button>
              </div>
              <div className="inner" title="View Ticket">
                <button
                  className="dropdown-item btn-popup-menu"
                  disabled={this.props.isLoading}
                  onClick={() => {
                    this.handleViewTiect(data);
                  }}
                >
                  <i className="fa fa-ticket" />
                </button>
              </div>
            </div>
          );
        },
      },
    ],
  };

  handleViewTiect(row) {
    window.location.replace(
      "/dashboard/ticketview?ticketId=" + row.ID + "&source=mytickets"
    );
  }

  handleMoreInfo(row) {
    const jsonData = {
      From: row.FROMX,
      Subject: row.SUBJECTX,
      Stauts: row.STATUSX,
      "Assigned To": row.ASSIGNED_TO,
      "Assigned Date": row.ASSIGNED_DATE,
      "Time Spent": row.TIME_SPENT,
      "Due Date": row.DUE_DATE,
      Category: row.CATEGORY,
      Priority: row.PRIORITY,
      "Soultion Location": row.SOLUTION_LOCATION,
    };

    confirmAlert({
      title: row.ID,
      childrenElement: () => (
        <InfoPopUp
          jsonData={jsonData}
          titlesColumnSize={4}
          valuesColumnSize={8}
          popUpWidth="600px"
        />
      ),
      buttons: [
        {
          label: "Close",
          onClick: () => {
            null;
          },
        },
      ],
    });
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    post(this.state.dataUrl, null, this.props.updateIsLoading).then(
      (apiResponse) => {
        this.setState({ tableData: apiResponse.data.respData.data });
      }
    );
  }

  render() {
    return (
      <div className="bms-content-component fade-in">
        <div className="row">
          <div className="col">
            <h4>{this.state.contentHeader}</h4>
          </div>
          <div className="col flex-container-left">
            {sysJwt.isAuthorized(["CAF9"]) && (
              <Button
                label="Create Ticket"
                icon="fa fa-plus"
                className="p-button-rounded p-button-secondary"
                disabled={this.props.isLoading}
                onClick={() => {
                  window.location.replace(
                    "/dashboard/newticket?source=mytickets"
                  );
                }}
              />
            )}
          </div>
        </div>
        <hr />
        <GridTable
          columns={this.state.tableColumns}
          rows={this.state.tableData}
          pageSizes={[10, 15, 20, 30, 40]}
          texts={{
            search: "Search:",
            totalRows: "Total Rows:",
            rows: "Row:",
            selected: "Selected",
            rowsPerPage: "Rows Per Page:",
            page: "Page:",
            of: "of",
            prev: "Pervious",
            next: "Next",
            columnVisibility: "Column Visibility",
          }}
        />
      </div>
    );
  }
}

export default MyTickets;
